.App {
  text-align: center;
}

.items{
  border: 1px solid;
  width: 60%;
  margin: auto;
  margin-bottom: 10px;
  
}
.addInkpen ,.remInkpen,.addPen,.remPen,.remNotebook,.addNotebook
,.addBook,.remBook{
  margin-right: 20%;
}